import { useForm } from '@inertiajs/react';
import { useEffect } from 'react';

const useInertiaForm = ({ defaultValues, defaultValid = false, validationSchema, transform, submit }) => {
  const form = useForm(defaultValues);

  useEffect(() => {
    form.setData(defaultValues);
  }, [JSON.stringify(defaultValues)]);

  if (typeof transform === 'function') {
    form.transform(transform);
  }

  return {
    inertia: form,
    initialValues: form.data,
    initialValid: defaultValid,
    validationSchema,
    onSubmit(values, formik) {
      const submitResponse =
        typeof submit === 'string'
          ? form.post(submit, {
              preserveScroll: true,
            })
          : submit(form, values, formik);

      if (typeof submitResponse?.finally === 'function') {
        submitResponse.finally(() => formik.setSubmitting(false));
      } else {
        formik.setSubmitting(false);
      }
    },
  };
};

export default useInertiaForm;
