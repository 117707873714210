import { Link } from '@inertiajs/react';
import { mdiLogout, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Panel from 'Components/Panel';
import { getThemeProperty, rootClassNames } from 'Layouts/Partials/SideBar';
import AddRecordPanel from 'Panels/Assemblies/AddRecordPanel';
import React, { useState } from 'react';
import useUser from 'Support/Hooks/useUser';

const MobileSideBar = ({ menu, quickAddRecord = true, open, onClose }) => {
  const user = useUser();
  const [addRecordOpen, setAddRecordOpen] = useState(false);

  return (
    <>
      <Panel open={open} onClose={onClose} left={true}>
        <div className="h-screen w-screen-3/4">
          <Panel.Body>
            <div className={cls('p-4 text-sm h-full', getThemeProperty('background'))}>
              {quickAddRecord && user.hasPermission('edit') && (
                <button onClick={() => setAddRecordOpen(true)} className={cls(rootClassNames, getThemeProperty('linkText'))}>
                  <div className="text-red-700">
                    <Icon path={mdiPlus} size={1} />
                  </div>
                  Add Record
                </button>
              )}

              {quickAddRecord && user.hasPermission('Create PO\'s') && (
                <Link href={route('purchase-orders.create')} className={cls(rootClassNames, getThemeProperty('linkText'))} onClick={onClose}>
                  <div className="text-red-700">
                    <Icon path={mdiPlus} size={1} />
                  </div>
                  Add PO
                </Link>
              )}

              {menu.map((item, index) => (
                <React.Fragment key={index}>
                  {item.inertia === false ? (
                    <a href={item.url} className={cls(rootClassNames, getThemeProperty('linkText'))} target="_blank" onClick={onClose}>
                      <Icon path={item.icon} size={1} />
                      {item.name}
                    </a>
                  ) : (
                    <Link href={item.url} className={cls(rootClassNames, getThemeProperty('linkText'))} onClick={onClose}>
                      <Icon path={item.icon} size={1} />
                      {item.name}
                    </Link>
                  )}
                </React.Fragment>
              ))}

              <div className="my-2 border-t" />

              <Link href={route('logout')} className={cls(rootClassNames, getThemeProperty('linkText'))}>
                <Icon path={mdiLogout} size={1} />
                Logout
              </Link>
            </div>
          </Panel.Body>
        </div>
      </Panel>

      <AddRecordPanel open={addRecordOpen} onClose={() => setAddRecordOpen(false)} />
    </>
  );
};

export default MobileSideBar;
