import { Menu } from '@headlessui/react';
import { mdiChevronDown, mdiGhostOffOutline, mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import React, { useState } from 'react';
import useUser from 'Support/Hooks/useUser';

const AccountMenu = () => {
  const { avatar, isGhosting, name, roles } = useUser();
  const [trigger, setTriggerElement] = useState(null);

  const accountMenuItems = [
    {
      title: 'Logged in as ' + name,
      as: 'div',
    },
    {
      title: roles.map((role) => role.charAt(0).toUpperCase() + role.slice(1)).join(', '),
      as: 'div',
    },
    {
      title: 'Log out',
      href: route('logout'),
      icon: mdiLogout,
      as: 'a',
    },
  ];

  return (
    <div className="relative inline-block shrink-0 text-left lg:mr-4">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button ref={setTriggerElement} className="focus-ring flex max-w-lg items-center rounded py-2 pl-4 text-sm">
              <div aria-haspopup="true" aria-label="User menu" className="flex max-w-full items-center sm:space-x-3" id="user-menu">
                <img className="h-8 w-8 rounded-full" src={avatar} alt={`${name} avatar`} />
                <div>{name}</div>
                <Icon path={mdiChevronDown} size={1} />
              </div>
            </Menu.Button>

            <MenuItemsWrapper show={open} trigger={trigger} width="w-64" grouped>
              {accountMenuItems?.map((item, index) => (
                <MenuItem icon={item.icon} href={item.href} as={item.as} key={index}>
                  {item.title}
                </MenuItem>
              ))}
              {isGhosting && (
                <MenuItem icon={mdiGhostOffOutline} href={route('ghost.user.stop')}>
                  Stop Ghosting
                </MenuItem>
              )}
            </MenuItemsWrapper>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
