import { Formik as BaseFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import FormikContext from 'Support/Contexts/FormikContext';

const Formik = ({ inertia, initialValid = false, children, readOnly = false, ...props }) => {
  const [manuallyDisabled, setManuallyDisabled] = useState(false);
  const contextValue = useMemo(
    () => ({
      validationSchema: props.validationSchema,
      setManuallyDisabled,
      readOnly,
      inertia,
    }),
    [props.validationSchema, inertia, readOnly]
  );

  return (
    <FormikContext.Provider value={contextValue}>
      <BaseFormik {...props}>
        {(formProps) => {
          useEffect(() => inertia && inertia.setData(formProps.values), [formProps.values]);
          useEffect(() => inertia && formProps.setErrors(inertia.errors), [inertia?.errors]);

          return typeof children === 'function'
            ? children({
                ...formProps,
                isReadOnly: readOnly,
                isSubmitting: formProps.isSubmitting || inertia?.processing,
                submitDisabled:
                  manuallyDisabled || inertia?.processing || formProps.isSubmitting || (!initialValid && !formProps.dirty) || !formProps.isValid,
              })
            : children;
        }}
      </BaseFormik>
    </FormikContext.Provider>
  );
};

Formik.displayName = 'CustomFormik';

export default Formik;
