import cls from 'classnames';
import { useField } from 'formik';
import React, { forwardRef, useCallback, useContext, useMemo } from 'react';
import FieldContext from 'Support/Contexts/FieldContext';
import { isEmptyValue } from 'Support/valueHelpers';
import { BaseSelectField } from './BaseSelectField';

const SelectField = forwardRef(({ labelKey, valueKey, ...props }, ref) => {
  const { name } = useContext(FieldContext);
  const { disabled, isMulti } = props;

  if (name === '') {
    throw new Error(`${SelectField.displayName} with missing FieldWrapper component`);
  }

  valueKey = valueKey || 'value';
  labelKey = labelKey || 'label';

  const [field, , { setValue, setTouched }] = useField(name);

  const getOptionValue = useCallback(
    props.getOptionValue ?? ((option) => (Array.isArray(option) ? option.map((item) => item[valueKey]) : option[valueKey])),
    [props.getOptionValue, valueKey]
  );

  const getOptionLabel = useCallback(props.getOptionLabel ?? ((option) => option[option.__isNew__ ? 'label' : labelKey]), [
    props.getOptionLabel,
    labelKey,
  ]);

  const formattedOptions = useMemo(() => {
    if (props.options === undefined) {
      return;
    }

    if (Array.isArray(props.options)) {
      return props.options;
    }
  }, [props.options]);

  const selectedOptionOrOptions = useMemo(() => {
    if (!formattedOptions) return null;
    if (!Array.isArray(formattedOptions)) return formattedOptions[field.value];

    const hasSubOptions = formattedOptions.some((option) => 'option' in option);
    const optionList = hasSubOptions ? formattedOptions.flatMap((group) => group.options) : formattedOptions;
    return Array.isArray(field.value)
      ? field.value.map((value) => optionList.find((option) => getOptionValue(option) === value))
      : optionList.find((option) => getOptionValue(option) === field.value);
  }, [field.value, formattedOptions, getOptionValue]);

  if (disabled) {
    return isMulti ? (
      <div
        className={cls(
          'h-10.5 border-1 text-blackish focus-ring w-full rounded-md border border-gray-200 pl-4 placeholder-gray-800',
          'flex flex-wrap gap-1 shadow shadow-gray-400/10 focus:bg-white'
        )}
        style={{
          padding: '7px 10px',
        }}
      >
        {props?.options
          .filter((option) => field.value.includes(option[valueKey]) || field.value === option[valueKey])
          .map((option) => (
            <div
              key={option[valueKey]}
              className="text-gray-800"
              style={{
                backgroundColor: 'hsl(0, 0%, 90%)',
                borderRadius: '2px',
                fontSize: '85%',
                padding: '3px 6px',
                gap: '2px',
              }}
            >
              {option[labelKey]}
            </div>
          ))}
      </div>
    ) : (
      <div
        className={cls(
          'h-10.5 border-1 text-blackish focus-ring w-full rounded-md border border-gray-200 py-2.5 pl-4 text-sm placeholder-gray-800',
          'bg-gray-100 shadow shadow-gray-400/10 focus:bg-white'
        )}
      >
        {(props?.options?.find((option) => option[valueKey] === field.value) || {})[labelKey] || '\u00A0'}
      </div>
    );
  }

  const onChange = (selectedOptionOrOptions, meta) => {
    setTouched(true);
    const value = props.isMulti
      ? selectedOptionOrOptions.map((opt) => getOptionValue(opt) || opt.value)
      : isEmptyValue(selectedOptionOrOptions)
      ? null
      : getOptionValue(selectedOptionOrOptions) || selectedOptionOrOptions.value;

    if (props.isMulti) {
      selectedOptionOrOptions
        .filter((opt) => opt.__isNew__)
        .map((opt) =>
          props.options.push({
            [labelKey]: opt.label,
            [valueKey]: opt.value,
          })
        );
    }

    if (meta.action === 'clear') {
      setValue(null, true);
    } else {
      setValue(value, true);
    }
    props.onChange?.(value, meta);
  };

  const modifiedProps = {
    ...props,
    name,
    getOptionLabel,
    getOptionValue,
    onChange,
    instanceId: `react-select-${name}`,
    options: formattedOptions,
    value: selectedOptionOrOptions,
    onBlur: () => setTouched(true),
  };

  return <BaseSelectField {...modifiedProps} ref={ref} />;
});

SelectField.displayName = 'FormikSelectField';

export default SelectField;
