import { Field } from 'formik';
import React, { useContext } from 'react';
import FieldContext from 'Support/Contexts/FieldContext';

const CheckboxField = ({ label, ...props }) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error('Select field with missing <VerticalFieldWrapper /> or empty name attribute on VerticalFieldWrapper component');
  }

  return (
    <div className="flex items-center gap-2">
      <Field type="checkbox" name={name} {...props} /> {label}
    </div>
  );
};

export default CheckboxField;
