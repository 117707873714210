import { usePage } from '@inertiajs/react';

const useUser = () => {
  const page = usePage();
  const user = page.props.application.user ?? {};

  const hasPermission = (...permissionNames) =>
    user.permissions ? user.permissions.some((permission) => permissionNames.includes(permission.name)) : false;

  const hasRole = (...roleNames) => (user.roles ? user.roles.some((role) => roleNames.includes(role)) : false);

  return { ...user, hasPermission, hasRole };
};

export default useUser;
