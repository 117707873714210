import { mdiBellOutline, mdiLinkVariant, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import AccountMenu from 'Layouts/Partials/AccountMenu';
import React from 'react';
import useInitialApplication from 'Support/Hooks/useInitialApplication';

const TopNav = ({ openNotification, showNotices, toggleSearchOpen }) => {
  const { bookmarks = [] } = useInitialApplication();

  return (
    <div className={cls('flex items-center justify-between border-b bg-white text-gray-700')}>
      <nav className="items-center px-4 py-2 text-sm">
        <ul className="flex gap-4">
          {bookmarks.map((bookmark, index) => (
            <li key={index}>
              <a href={bookmark.link} target={bookmark.target ? '_blank' : null} className="flex items-center gap-1 hover:text-red-600">
                <Icon path={mdiLinkVariant} size={0.8} /> {bookmark.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className="flex items-center">
        {showNotices && (
          <button onClick={openNotification} className="mr-4 w-6 animate-pulse rounded-full bg-red-600">
            <Icon path={mdiBellOutline} size={1} />
          </button>
        )}

        <div className="flex items-center border-r pr-3">
          <button onClick={toggleSearchOpen}>
            <Icon path={mdiMagnify} size={1} />
          </button>
        </div>

        <AccountMenu />
      </div>
    </div>
  );
};

export default TopNav;
