import cls from 'classnames';
import React from 'react';
import useInitialApplication from 'Support/Hooks/useInitialApplication';

const Logo = ({ className = 'max-h-12', inAdmin = false }) => {
  const { logo, site_name } = useInitialApplication();

  return !inAdmin && logo ? (
    <img src={logo} className={cls(className, 'my-4 px-2')} alt={site_name} />
  ) : (
    <div className="m-4 flex items-center gap-3">
      <img src="/static/img/logo-trans.png" className={className} alt="esteel" />
    </div>
  );
};

export default Logo;
