import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import MediaLibraryCollection from 'Components/MediaLibraryPro/MediaLibraryCollection';
import { Field, useField } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { MediaLibraryAttachment } from 'spatie-media-library-pro/media-library-pro-react-attachment';
import axios from 'Support/axios';
import FieldContext from 'Support/Contexts/FieldContext';
import FormikContext from 'Support/Contexts/FormikContext';

const FileDisplay = ({ file }) => (
  <div className="flex grow flex-col bg-white">
    <a
      href={file.original_url}
      target="_blank"
      className={cls(
        'hover:text-blue-850 cursor-pointer text-blue-700 transition focus:text-gray-500 focus:outline-none',
        'px-4 py-2 duration-150 ease-in-out'
      )}
    >
      {file.name}
    </a>
  </div>
);

const SpatieUploadField = ({ disabled = false, imageOnly = false, initialValue, mediaUrl, multiFile = false, ...props }) => {
  const { name } = useContext(FieldContext);
  const { setManuallyDisabled } = useContext(FormikContext);
  const [field, , { setValue }] = useField(name);
  const [loaded, setLoaded] = useState();

  if (name === '') {
    throw new Error('Switch field with missing <VerticalFieldWrapper /> or empty name attribute on VerticalFieldWrapper component');
  }

  useEffect(() => {
    mediaUrl &&
      axios.get(mediaUrl).then((response) => {
        setValue(response.data);
        setLoaded(true);
      });

    //For adding and you don't have a media url
    if (!mediaUrl) {
      setLoaded(true);
    }
  }, [mediaUrl]);

  return loaded ? (
    disabled ? (
      <div className="divide-y divide-gray-300 border-2 border-gray-200 bg-gray-300">
        {(initialValue || Object.values(field.value)).map((file, index) => (
          <FileDisplay file={file} key={index} />
        ))}
      </div>
    ) : (
      <Field
        className="w-full"
        component={multiFile ? MediaLibraryCollection : MediaLibraryAttachment}
        name={name}
        onIsReadyToSubmitChange={(ready) => setManuallyDisabled(!ready)}
        initialValue={initialValue ? initialValue : field.value}
        onChange={setValue}
        translations={{
          selectOrDragMax: multiFile ? 'Select or drag max {maxItems} {file} here' : 'Select or drag a file here',
        }}
        propertiesView={({ object }) => <FileDisplay file={object.attributes} />}
        validationRules={imageOnly ? { accept: ['image/png', 'image/jpeg'] } : {}}
        {...props}
      />
    )
  ) : (
    <Icon path={mdiLoading} size={1} className="animate-spin" />
  );
};

export default SpatieUploadField;
