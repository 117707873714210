import React, { forwardRef, useMemo } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import theme from 'tailwind-theme';

export const styles = {
  container: (provided, state) => ({}),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 6,
    // paddingLeft: 6,
    // minHeight: theme.height['10.5'],
    paddingTop: 2,
    paddingBottom: 2,
    ...(state.isFocused
      ? {
          backgroundColor: 'white',
          '--tw-border-opacity': 1,
          borderColor: 'rgba(209, 213, 219, var(--tw-border-opacity))',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          '--tw-ring-offset-shadow': 'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)',
          '--tw-ring-shadow': 'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
          boxShadow: 'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)',
          '--tw-ring-color': 'rgba(209, 213, 219, var(--tw-ring-opacity))',
          '--tw-ring-opacity': 0.2,
          '&:hover': {
            borderColor: 'rgba(209, 213, 219, var(--tw-border-opacity))',
          },
        }
      : {
          boxShadow: theme.boxShadow.input,
          backgroundColor: 'white',
          borderColor: theme.colors.gray[300],
          '&:hover': {
            borderColor: theme.colors.gray[300],
          },
        }),
  }),
  input: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.gray[800],
    fontSize: theme.fontSize['sm'][0],
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.transparent,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 5,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
    backgroundColor: 'white',
    marginTop: 0,
    marginBottom: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const OptionWithDescription = ({ children, ...props }) => {
  const DefaultOption = components.Option;

  if (!('description' in props.data)) return <DefaultOption {...props}>{children}</DefaultOption>;

  return (
    <DefaultOption {...props}>
      <div>{children}</div>
      <div className="text-gray-750 text-sm">{props.data.description}</div>
    </DefaultOption>
  );
};

export const BaseSelectField = forwardRef((props, ref) => {
  const defaultStyles = useMemo(() => styles, []);

  const modifiedProps = {
    ...props,
    placeholder: 'Type or select',
    styles: props.styles ?? defaultStyles,
    onChange(option, meta) {
      // block removal of options if they are fixed
      if (meta.action === 'remove-value' || meta.action === 'pop-value') {
        if (typeof meta.removedValue === 'object' && meta.removedValue?.fixed) {
          return;
        }
      }

      props.onChange?.(option, meta);
    },
    components: {
      Option: OptionWithDescription,
      ...props.components,
    },
  };

  const Component = props.isCreatable ? CreatableSelect : Select;

  return <Component ref={ref} {...modifiedProps} className="rounded-md shadow shadow-gray-400/10" />;
});
