import { Link, usePage } from '@inertiajs/react';
import { mdiArrowCollapseLeft, mdiArrowCollapseRight, mdiChevronLeft, mdiLoading, mdiMagnify, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Logo from 'Layouts/Partials/Logo';
import AddRecordPanel from 'Panels/Assemblies/AddRecordPanel';
import React, { useState } from 'react';
import useUser from 'Support/Hooks/useUser';

export const getThemeProperty = (property, theme) => {
  theme = theme || usePage().props.application.theme || 'default';

  const themes = {
    default: {
      background: 'bg-white',
      highlightText: 'text-gray-900',
      linkHover: 'hover:bg-blue-100/50',
      linkText: 'text-gray-700',
    },
    dark: {
      background: 'bg-gray-900',
      highlightText: 'text-gray-200',
      linkHover: 'hover:text-gray-300',
      linkText: 'text-gray-400',
    },
    high_contrast: {
      background: 'bg-black',
      highlightText: 'text-white',
      linkHover: 'hover:bg-slate-700',
      linkText: 'text-white',
    },
  };

  return themes[theme?.value || theme][property];
};

export const rootClassNames = 'flex w-full items-center gap-2 rounded-md p-2 text-sm';

const RootLink = ({ item, collapsed }) => {
  const [clicked, setClicked] = useState(false);

  return item.inertia === false ? (
    <a href={item.url} className={cls(rootClassNames, getThemeProperty('linkText'), collapsed ? 'pl-2' : 'px-6')} onClick={() => setClicked(true)}>
      {item.icon && (clicked ? <Icon path={mdiLoading} size={0.9} className="animate-spin" /> : <Icon path={item.icon} size={1} />)}
      {!collapsed && item.name}
    </a>
  ) : (
    <Link
      href={item.url}
      className={cls(rootClassNames, getThemeProperty('linkText'), collapsed ? 'px-0' : getThemeProperty('linkHover'))}
      onClick={() => setClicked(true)}
      onSuccess={() => setClicked(false)}
    >
      {item.icon && (clicked ? <Icon path={mdiLoading} size={0.9} className="animate-spin" /> : <Icon path={item.icon} size={0.9} />)}
      {!collapsed && item.name}
    </Link>
  );
};

const SubMenu = ({ item, collapsed }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className={cls('mt-2 flex cursor-pointer items-center justify-between border-t pt-3 font-semibold', getThemeProperty('highlightText'))}
        onClick={() => setOpen(!open)}
      >
        {collapsed ? <Icon path={item?.icon} size={0.9} /> : item.name}
        <Icon path={mdiChevronLeft} size={1} className={cls('transition-transform', open && '-rotate-90')} />
      </div>
      <div
        className={cls(
          'overflow-hidden transition-all',
          open ? 'h-auto' : 'h-0 p-0',
          collapsed ? 'absolute bottom-0 left-14 top-12 z-50 border-r border-t bg-white' : 'mb-4 mt-2',
          open && collapsed && 'p-4',
        )}
      >
        {item?.sub.map((item, index) => (
          <RootLink item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

const SideBar = ({ menu, quickAddRecord = true, collapsed, inAdmin, setCollapsed, toggleSearchOpen }) => {
  const [addRecordOpen, setAddRecordOpen] = useState(false);
  const user = useUser();

  return (
    <div className={cls('z-50 overflow-y-auto overflow-x-hidden border-r', getThemeProperty('background'))}>
      <div className="flex items-center justify-between gap-2">
        {!collapsed && (
          <Link href={route('dashboard')}>
            <Logo inAdmin={inAdmin} />
          </Link>
        )}

        <button onClick={() => setCollapsed(!collapsed)} className={cls('m-4')}>
          <Icon path={collapsed ? mdiArrowCollapseRight : mdiArrowCollapseLeft} size={1} className={getThemeProperty('highlightText')} />
        </button>
      </div>

      <div className={cls(!collapsed && 'flex items-center justify-between')}>
        {toggleSearchOpen && (
          <button onClick={toggleSearchOpen} className={cls(rootClassNames, getThemeProperty('linkText'), collapsed ? 'pl-2' : 'px-6')}>
            <Icon path={mdiMagnify} size={1} />
          </button>
        )}
      </div>

      <div className="px-4 text-sm">
        {quickAddRecord && user.hasPermission('edit') && (
          <button
            onClick={() => setAddRecordOpen(true)}
            className={cls(rootClassNames, getThemeProperty('linkText'), collapsed ? 'px-0' : getThemeProperty('linkHover'), 'text-red-700')}
          >
            <Icon path={mdiPlus} size={1} />
            {!collapsed && <>Add Record</>}
          </button>
        )}

        {menu.map((item, index) =>
          item?.url ? <RootLink item={item} key={index} collapsed={collapsed} /> : <SubMenu item={item} key={index} collapsed={collapsed} />,
        )}
      </div>

      <AddRecordPanel open={addRecordOpen} onClose={() => setAddRecordOpen(false)} />
    </div>
  );
};

export default SideBar;
