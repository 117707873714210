import { Dialog, Transition } from '@headlessui/react';
import cls from 'classnames';
import React, { createContext, Fragment, useContext, useMemo } from 'react';

export const Panel = ({ open, onClose, children, left = false }) => {
  const contextValue = useMemo(() => ({ open, closePanel: () => onClose(false) }), [onClose, open]);

  return (
    <PanelContext.Provider value={contextValue}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-50 overflow-hidden" open={contextValue.open} onClose={contextValue.closePanel}>
          <div className="absolute inset-0 overflow-hidden">
            <Panel.Overlay />

            <div className={cls('fixed inset-y-0 flex max-w-full', left ? 'left-0' : 'right-0 pl-10')}>
              <Panel.Transition left={left}>{children}</Panel.Transition>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </PanelContext.Provider>
  );
};

const PanelActions = ({ children, withoutMargin = false }) => (
  <div className={cls('flex shrink-0 justify-end space-x-3 border-t p-4', withoutMargin ? '' : 'mt-4')}>{children}</div>
);

const PanelBody = ({ as = 'div', children, className = '', ...props }) => {
  const Element = as;

  const childrenMapper = (map, child) => {
    if (child.type === Panel.Header) {
      map.headerComponent = child;
    } else if (child.type === Panel.Actions) {
      map.actionsComponent = child;
    } else {
      map.otherChildren.push(child);
    }

    return map;
  };

  const { headerComponent, actionsComponent, otherChildren } = React.Children.toArray(children).reduce(childrenMapper, {
    headerComponent: undefined,
    actionsComponent: undefined,
    otherChildren: [],
  });

  return (
    <Element className={cls('flex h-full flex-col overflow-y-auto bg-white shadow-xl shadow-gray-400/10', className)} {...props}>
      <div className="flex-1">
        {headerComponent}

        {as?.displayName === 'Form' ? <div className="space-y-4 p-4">{otherChildren}</div> : otherChildren}
      </div>

      {actionsComponent}
    </Element>
  );
};

const PanelHeader = ({ title, subTitle, children }) => {
  const { closePanel } = useContext(PanelContext);

  return (
    <div className="border-b p-4">
      <div className="flex items-center justify-between space-x-4">
        {children ? (
          children
        ) : (
          <div className="space-y-1">
            <Dialog.Title className="font-semibold text-gray-700">{title}</Dialog.Title>
            {subTitle ? <Dialog.Description className="mt-12 text-sm text-gray-100">{subTitle}</Dialog.Description> : null}
          </div>
        )}
        <div className="flex items-center">
          <Panel.Header.CloseButton onClose={closePanel} />
        </div>
      </div>
    </div>
  );
};

const PanelHeaderCloseButton = ({ onClose }) => (
  <button type="button" onClick={onClose} tabIndex="-1" className="text-gray-600 duration-200 hover:rotate-180 hover:text-gray-900 focus:outline-none">
    <span className="sr-only">Close panel</span>
    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
);

const PanelOverlay = () => (
  <Transition.Child
    as={Fragment}
    enter="transition ease-out duration-250 sm:duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition ease-out duration-250 sm:duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <Dialog.Overlay className="absolute inset-0 bg-black/20" />
  </Transition.Child>
);

const PanelTransition = ({ children, left = false }) => (
  <Transition.Child
    as={Fragment}
    enter="transform transition ease-out duration-250 sm:duration-300"
    enterFrom={left ? '-translate-x-full' : 'translate-x-full'}
    enterTo="translate-x-0"
    leave="transform transition ease-out duration-250 sm:duration-300"
    leaveFrom="translate-x-0"
    leaveTo={left ? '-translate-x-full' : 'translate-x-full'}
  >
    {children}
  </Transition.Child>
);

export const PanelContext = createContext({
  open: false,
  openPanel() {},
  closePanel() {},
});

PanelContext.displayName = 'Panel.Context';

Panel.Actions = PanelActions;
Panel.Body = PanelBody;
Panel.Context = PanelContext;
Panel.Header = PanelHeader;
Panel.Header.CloseButton = PanelHeaderCloseButton;
Panel.Overlay = PanelOverlay;
Panel.Transition = PanelTransition;

export default Panel;
