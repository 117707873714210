import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Formik from 'Components/Forms/Formik';
import { Form } from 'formik';
import React, { useEffect, useRef } from 'react';
import useInertiaForm from 'Support/Hooks/useInertiaForm';

const SearchField = ({ open, setOpen }) => {
  const ref = useRef();

  const formikProps = useInertiaForm({
    defaultValues: {
      search: '',
    },
    submit: ({ data }) => (window.location = route('search.index') + '?' + new URLSearchParams(data).toString()),
  });

  const overlay = useRef();

  const handleClick = (e) => {
    if (e.target === overlay.current) {
      setOpen(false);
    }
  };

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      setOpen(false);
    }
  };

  document.addEventListener('keydown', escFunction, false);

  useEffect(() => {
    if (open) {
      ref.current.focus();
    }
  }, [open]);

  return (
    <div
      onClick={handleClick}
      ref={overlay}
      className={cls('absolute bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-black/20', open ? '' : 'hidden')}
    >
      <Formik {...formikProps}>
        {({ values, setFieldValue }) => (
          <Form className="absolute w-screen animate-slide-in">
            <div className="flex w-full animate-fade-in justify-center text-xl">
              <input
                name="search"
                value={values.search}
                onChange={(e) => setFieldValue('search', e.target.value)}
                className="w-1/2 rounded-l-md border-0 px-4 text-black focus:ring-0"
                placeholder="Project/Quote/Variation/Assembly/Part search..."
                ref={ref}
              />

              <button type="submit" className="rounded-r-md bg-white px-2 py-2">
                <Icon path={mdiMagnify} size={1.3} className="rounded bg-gray-100 p-1 text-gray-700" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchField;
