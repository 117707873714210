import { Head, Link, usePage } from '@inertiajs/react';
import {
  mdiAccountOutline,
  mdiBookOpenVariant,
  mdiBorderAllVariant,
  mdiBorderOutside,
  mdiCardAccountDetailsOutline,
  mdiChartTimelineVariant,
  mdiClockOutline,
  mdiCogOutline,
  mdiContactsOutline,
  mdiCubeOutline,
  mdiFileDocumentMultipleOutline,
  mdiFileDocumentOutline,
  mdiFileMultipleOutline,
  mdiFileOutline,
  mdiMagnify,
  mdiMenu,
  mdiNotebookOutline,
  mdiNoteMultipleOutline,
  mdiOrderBoolDescendingVariant,
  mdiPostOutline,
  mdiViewDashboardOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Logo from 'Layouts/Partials/Logo';
import MobileSideBar from 'Layouts/Partials/MobileSideBar';
import NoticeSideBar from 'Layouts/Partials/NoticeSideBar';
import SearchField from 'Layouts/Partials/SearchField';
import SideBar, { getThemeProperty } from 'Layouts/Partials/SideBar';
import TopNav from 'Layouts/Partials/TopNav';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ScrollContext from 'Support/Contexts/ScollContext';
import useFeatures from 'Support/Hooks/useFeatures';
import useInitialApplication from 'Support/Hooks/useInitialApplication';
import useUser from 'Support/Hooks/useUser';

const MainLayout = ({ children }) => {
  const user = useUser();
  const { site_name, icon } = useInitialApplication();
  const { flash, topNotices, page_title } = usePage().props;
  const [collapsed, setCollapsed] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollWrapper = useRef();

  const features = useFeatures();

  const menu = useMemo(
    () =>
      [
        {
          icon: mdiViewDashboardOutline,
          name: 'Project Dashboard',
          url: route('project.dashboard'),
        },
        {
          icon: mdiNotebookOutline,
          name: 'Quotes',
          url: route('quotes.index'),
        },
        {
          icon: mdiFileMultipleOutline,
          name: 'Projects',
          url: route('projects.index'),
        },
        // {
        //   icon: mdiViewGridOutline,
        //   name: 'Variations',
        //   url: route('variations.index'),
        // },
        {
          icon: mdiFileDocumentOutline,
          name: 'Assemblies',
          url: route('assemblies.index'),
        },
        {
          icon: mdiFileDocumentMultipleOutline,
          name: 'Parts',
          url: route('parts.index'),
        },
        {
          icon: mdiBorderAllVariant,
          name: 'Plate Stock',
          url: route('plate-stock.index'),
          filter: features['plate-stock'],
        },
        {
          icon: mdiBorderOutside,
          name: 'Plate Nests',
          url: route('plate-nests.index'),
          filter: features['plate-nests'],
        },
        {
          icon: mdiOrderBoolDescendingVariant,
          name: 'Purchase Orders',
          url: route('purchase-orders.index'),
        },
        {
          icon: mdiFileOutline,
          name: 'Minor Projects',
          url: route('minor-projects.index'),
        },
        {
          icon: mdiAccountOutline,
          name: 'Companies',
          url: route('companies.index'),
        },
        {
          icon: mdiContactsOutline,
          name: 'Contacts',
          url: route('contacts.index'),
        },
        {
          icon: mdiCardAccountDetailsOutline,
          name: 'Employees',
          url: route('employees.index'),
        },
        {
          icon: mdiClockOutline,
          name: 'Timesheets',
          url: route('timesheets.index', 'this-week'),
        },
        {
          icon: mdiNoteMultipleOutline,
          name: 'Documents',
          url: route('documents.index'),
        },
        {
          icon: mdiChartTimelineVariant,
          name: 'Reports',
          url: route('reports.index'),
        },
        {
          icon: mdiPostOutline,
          name: 'Notices',
          url: route('notices.index'),
        },
        {
          icon: mdiBookOpenVariant,
          name: 'Resources',
          url: route('resources.index'),
        },
        {
          icon: mdiCubeOutline,
          name: 'Consumables',
          url: route('consumables.index'),
        },
        {
          icon: mdiFileOutline,
          name: 'File Browser',
          url: route('s3.index'),
        },
        {
          icon: mdiCogOutline,
          name: 'Management',
          filter: user.hasPermission('manage'),
          sub: [
            {
              name: 'Project Types',
              url: route('project-types.index'),
            },
            {
              name: 'Document Types',
              url: route('document-types.index'),
            },
            {
              name: 'Task Types',
              url: route('task-types.index'),
            },
            {
              name: 'Method Secured Types',
              url: route('method-secured-types.index'),
            },
            {
              name: 'Statuses',
              url: route('status-titles.index'),
            },
            {
              name: 'Plate Grades',
              url: route('plate-grades.index'),
              filter: features['plate-stock'],
            },
            {
              name: 'Cost Codes',
              url: route('cost-codes.index'),
            },
            {
              name: 'Bookmarks',
              url: route('bookmarks.index'),
            },
            {
              name: 'Employee Groups',
              url: route('employee-groups.index'),
            },
            {
              name: 'Certifications',
              url: route('certifications.index'),
            },
            {
              name: 'Storage Folders',
              url: route('storage-folder.index'),
            },
            {
              name: 'Users',
              url: route('users.index'),
            },
            {
              name: 'Settings',
              url: route('settings.index'),
            },
          ].filter((item) => item?.filter !== false),
        },
      ].filter((item) => item?.filter !== false),
    [JSON.stringify(user), features['plate-nests'], features['plate-stock']],
  );

  const mobileMenu = useMemo(
    () =>
      [
        {
          icon: mdiNotebookOutline,
          name: 'Quotes',
          url: route('quotes.index'),
          inertia: true,
          filter: user.hasRole('admin') || user.hasRole('developer') || user.hasRole('standard') || user.hasRole('super_admin'),
        },
        {
          icon: mdiFileMultipleOutline,
          name: 'Projects',
          url: route('projects.index'),
          inertia: true,
        },
        {
          icon: mdiFileDocumentOutline,
          name: 'Assemblies',
          url: route('assemblies.index'),
          inertia: true,
        },
        {
          icon: mdiFileDocumentMultipleOutline,
          name: 'Parts',
          url: route('parts.index'),
          inertia: true,
        },
        {
          icon: mdiOrderBoolDescendingVariant,
          name: 'Purchase Orders',
          url: route('purchase-orders.index'),
          inertia: true,
        },
        {
          icon: mdiAccountOutline,
          name: 'Companies',
          url: route('companies.index'),
          inertia: true,
          filter: user.hasRole('admin') || user.hasRole('developer') || user.hasRole('standard') || user.hasRole('super_admin'),
        },
        {
          icon: mdiContactsOutline,
          name: 'Contacts',
          url: route('contacts.index'),
          inertia: true,
        },
        {
          icon: mdiCardAccountDetailsOutline,
          name: 'Employees',
          url: route('employees.index'),
          inertia: true,
        },
        {
          icon: mdiFileOutline,
          name: 'Files',
          url: route('s3.index'),
          inertia: true,
        },
        {
          icon: mdiBookOpenVariant,
          name: 'Resources',
          url: route('resources.index'),
          inertia: true,
        },
      ].filter((item) => item?.filter !== false),
    [],
  );

  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, message }) => {
        toast(message, { type });
      });
    }
  }, [JSON.stringify(flash)]);

  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileSideNavOpen, setMobileSideNavOpen] = useState(false);

  const onScroll = () => {
    setScrollTop(scrollWrapper.current.getBoundingClientRect().top);
  };

  return (
    <>
      <Head title={page_title ? `${page_title} | ${site_name}` : site_name}>
        <link rel="icon" type="image/png" href={icon} />
      </Head>

      <SearchField open={searchOpen} setOpen={setSearchOpen} />

      <div className={cls('flex h-screen flex-col font-main', user.hasPermission('view-desktop') && 'md:hidden')}>
        <div
          className={cls('flex h-16 flex-row items-center justify-between px-5', getThemeProperty('background'), getThemeProperty('highlightText'))}
        >
          <button onClick={() => setMobileSideNavOpen(true)}>
            <Icon path={mdiMenu} size={1.5} />
          </button>
          <Link href={route('dashboard')} className="flex grow justify-center">
            <Logo className="max-h-16" />
          </Link>
          {!user.hasRole('restricted') && (
            <button onClick={() => setSearchOpen(!searchOpen)}>
              <Icon path={mdiMagnify} size={1.5} />
            </button>
          )}
        </div>
        {children}
      </div>

      <MobileSideBar menu={mobileMenu} open={mobileSideNavOpen} onClose={() => setMobileSideNavOpen(false)} />

      <div
        className={cls(
          'hidden h-screen grid-flow-col overflow-hidden font-main',
          collapsed ? 'grid-cols-collapsed' : 'grid-cols-main',
          user.hasPermission('view-desktop') && 'md:grid',
        )}
      >
        <SideBar menu={menu} collapsed={collapsed} setCollapsed={setCollapsed} />

        <div className="flex flex-col overflow-y-auto overflow-x-hidden bg-gray-50">
          <TopNav
            openNotification={() => setNotificationsOpen(true)}
            showNotices={topNotices.length > 0}
            toggleSearchOpen={() => setSearchOpen(!searchOpen)}
          />
          <div className="relative flex h-screen w-full flex-col overflow-x-hidden overflow-y-visible" scroll-region="true" onScroll={onScroll}>
            <NoticeSideBar notices={topNotices} open={notificationsOpen} onClose={() => setNotificationsOpen(false)} />
            <div ref={scrollWrapper}>
              <ScrollContext.Provider value={{ sidebarCollapsed: collapsed, top: scrollTop }}>{children}</ScrollContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
