import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import { ErrorMessage, FormikContext } from 'formik';
import React, { useContext } from 'react';
import { FieldContext } from 'Support/Contexts/FieldContext';
import ValidationFormikContext from 'Support/Contexts/FormikContext';
import theme from 'Support/tailwind-theme';

const VerticalFieldWrapper = ({
  as = 'label',
  children,
  className,
  fullHeight,
  hint,
  ignoreMissing = false,
  label,
  labelClasses = 'text-gray-800 text-sm',
  name,
  requiredStar,
}) => {
  const { validationSchema } = useContext(ValidationFormikContext);
  const { initialValues } = useContext(FormikContext);
  const WrapperElement = as;
  const LabelElement = as === 'label' ? 'div' : 'label';

  if (!ignoreMissing && !(name in initialValues)) {
    throw new Error(`Field '${name}' not registered in formik initial values`);
  }

  const field = validationSchema.describe().fields[name];
  const isRequired =
    requiredStar !== false && (requiredStar === true || (field?.type !== 'boolean' && field?.tests.some((test) => test.name === 'required')));

  return (
    <WrapperElement className={cls('block space-y-2', className)}>
      {label && (
        <LabelElement className={labelClasses} htmlFor={LabelElement === 'label' ? `field_${name}` : null}>
          {label}
          {isRequired && <span className="text-red-600">*</span>}
        </LabelElement>
      )}

      <div className={cls(fullHeight && 'flex grow')}>
        <FieldContext.Provider value={{ name }}>
          <div className={cls('relative', fullHeight && 'flex grow')}>{children}</div>
        </FieldContext.Provider>

        <ErrorMessage
          name={name}
          component="div"
          className="-mt-1 ml-auto rounded bg-red-500 p-2 pt-3 text-xs text-white shadow-sm shadow-gray-400/10"
        />

        {hint && (
          <div className="text-gray-750 flex items-start pt-2 text-sm">
            <Icon path={mdiInformationOutline} size={theme.fontSize.sm[0]} className="text-cyan mr-1 shrink-0" /> {hint}
          </div>
        )}
      </div>
    </WrapperElement>
  );
};

export default VerticalFieldWrapper;
