import cls from 'classnames';
import { format as formatDate, startOfDay } from 'date-fns';
import { Field, useField } from 'formik';
import React, { forwardRef, useContext } from 'react';
import FieldContext from 'Support/Contexts/FieldContext';

export const DefaultInputFieldClasses = cls(
  'h-10.5 pl-4 border-1 border-gray-200 text-blackish rounded-md placeholder-gray-800 text-sm py-2.5 w-full',
  'focus-ring focus:bg-white shadow shadow-gray-400/10',
);

const TextField = ({ type = 'text', className = '', ...props }, ref) => {
  const { name } = useContext(FieldContext);
  const [, , { setValue }] = useField(name);
  const { disabled } = props;

  if (name === '') {
    throw new Error('Text field with missing <VerticalFieldWrapper /> or empty name attribute on VerticalFieldWrapper component');
  }

  let format = '';
  let formatDates = false;

  if (type === 'date') {
    format = 'yyyy-MM-dd';
    formatDates = true;
  } else if (type === 'datetime') {
    type = 'datetime-local';
    format = "yyyy-MM-dd'T'HH:mm";
    formatDates = true;
  }

  if (formatDates && props.max === 'today') {
    props.max = formatDate(startOfDay(new Date()), format);
  }

  if (formatDates && props.min === 'today') {
    props.min = formatDate(startOfDay(new Date()), format);
  }

  if (formatDates && props.max === 'now') {
    props.max = formatDate(new Date(), format);
  }

  if (formatDates && props.min === 'now') {
    props.min = formatDate(new Date(), format);
  }

  if (formatDates && !props.max) {
    props.max = '9999-12-31';
  }

  if (!className.includes('bg-')) {
    className += disabled ? ' bg-white' : ' bg-white';
  }

  if (type === 'url') {
    props.onKeyUp = (event) => {
      let url = event.target.value;
      console.log(url, url.length);
      if (url?.length > 0) {
        url = !url.includes('://') ? 'https://' + url : 'https://' + url.split('://')[1];
      }
      setValue(url);
    };
  }

  return <Field className={cls(DefaultInputFieldClasses, className)} innerRef={ref} name={name} type={type} {...props} />;
};

export default forwardRef(TextField);
