import { router } from '@inertiajs/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import Panel from 'Components/Panel';
import React from 'react';

const NoticeSideBar = ({ notices, open, onClose }) => {
  const onClick = (notice) => {
    onClose();
    router.visit(notice.href);
  };

  return (
    <Panel open={open} onClose={onClose}>
      <div className="w-screen max-w-md">
        <Panel.Body>
          <Panel.Header title="Notices" />

          <div className="relative top-10 flex w-full flex-col items-center justify-center px-4">
            {notices.map((notice) => (
              <button key={notice.href} className="my-1 w-full py-1 text-left hover:cursor-pointer hover:underline" onClick={() => onClick(notice)}>
                {notice.message}
              </button>
            ))}
          </div>

          <Panel.Actions>
            <PrimaryButton type="button" onClick={onClose}>
              Close
            </PrimaryButton>
          </Panel.Actions>
        </Panel.Body>
      </div>
    </Panel>
  );
};

export default NoticeSideBar;
