import cls from 'classnames';
import React, { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { HiddenFields, Icon, Icons, ItemErrors, ListErrors, Thumb, Uploader, useMediaLibrary } from 'spatie-media-library-pro/media-library-pro-react';

const DefaultPropertiesView = ({ object }) => (
  <div className="media-library-properties media-library-properties-fixed">
    {object.attributes.extension && <div className="media-library-property">{object.attributes.extension.toUpperCase()}</div>}
    {object.attributes.size && <div className="media-library-property">{(object.attributes.size / 1024).toFixed(2)} KB</div>}
    {object.attributes.original_url && (
      <div className="media-library-property">
        <a href={object.attributes.original_url} download target="_blank" className="media-library-text-link">
          {window.mediaLibraryTranslations.download}
        </a>
      </div>
    )}
  </div>
);

const DefaultFieldsView = ({ getNameInputProps, getNameInputErrors }) => {
  const nameInputProps = getNameInputProps();
  const duskProps = { dusk: 'media-library-field-name' };

  return (
    <div className="media-library-properties">
      <div className="media-library-field">
        <label className="media-library-label">{window.mediaLibraryTranslations.name}</label>
        <input className="media-library-input" {...nameInputProps} {...duskProps} />
        {getNameInputErrors().map((error) => (
          <p key={error} className="media-library-field-error">
            {error}
          </p>
        ))}
      </div>
    </div>
  );
};

const MediaLibraryCollection = ({
  name,
  initialValue = [],
  translations = {},
  validationRules,
  validationErrors = {},
  routePrefix,
  sortable = true,
  maxItems,
  maxSizeForPreviewInBytes,
  vapor,
  vaporSignedStorageUrl,
  uploadDomain,
  withCredentials,
  headers,
  fileTypeHelpText,
  setMediaLibrary,
  propertiesView = DefaultPropertiesView,
  fieldsView = DefaultFieldsView,
  beforeUpload = () => {},
  afterUpload = () => {},
  onChange = () => {},
  onIsReadyToSubmitChange = () => {},
  capture,
}) => {
  const {
    mediaLibrary,
    state,
    getImgProps,
    getNameInputProps,
    getNameInputErrors,
    getCustomPropertyInputProps,
    getCustomPropertyInputErrors,
    getFileInputProps,
    getDropZoneProps,
    setOrder,
    removeMedia,
    replaceMedia,
    getErrors,
    clearObjectErrors,
    clearInvalidMedia,
    isReadyToSubmit,
  } = useMediaLibrary({
    name,
    initialValue: initialValue,
    validationErrors: Array.isArray(validationErrors) ? {} : validationErrors,
    routePrefix,
    validationRules,
    translations,
    maxItems,
    maxSizeForPreviewInBytes,
    vapor,
    vaporSignedStorageUrl,
    uploadDomain,
    withCredentials,
    headers,
    beforeUpload,
    afterUpload,
    onChange,
  });

  React.useEffect(() => {
    onIsReadyToSubmitChange(isReadyToSubmit);
  }, [isReadyToSubmit]);

  React.useEffect(() => {
    if (setMediaLibrary && mediaLibrary) {
      setMediaLibrary(mediaLibrary);
    }
  }, [setMediaLibrary, mediaLibrary]);

  const dropZoneProps = getDropZoneProps();
  const fileInputProps = getFileInputProps();

  const items = state.media.map((object) => {
    return {
      id: object.attributes.uuid,
      content: object,
    };
  });

  const [listState, setListState] = useState(items);

  return (
    <>
      <Icons />

      <div
        className={cls(
          'media-library media-library-multiple',
          state.media.length === 0 ? 'media-library-empty' : 'media-library-filled',
          sortable && 'media-library-sortable',
        )}
      >
        <ListErrors
          invalidMedia={state.invalidMedia}
          topLevelErrors={Array.isArray(validationErrors) ? undefined : validationErrors[name]}
          onClear={clearInvalidMedia}
        />

        {state.media?.length > 0 && (
          <div className="media-library-items">
            <ReactSortable
              list={listState}
              setList={setListState}
              onEnd={(evt) => {
                const source = evt.from;

                setOrder(
                  Array.from(source?.children || []).map((element) => {
                    return element.getAttribute('data-media-library-uuid');
                  }),
                );
              }}
            >
              {state.media.map((object) => {
                const objectErrors = getErrors(object);
                return (
                  <div
                    className="media-library-item media-library-item-row flex items-center gap-2"
                    key={object.attributes.uuid}
                    data-media-library-uuid={object.attributes.uuid}
                  >
                    {sortable && <div className="drag-handle media-library-row-drag">{sortable && <Icon icon="drag" />}</div>}

                    <Thumb
                      uploadInfo={object.upload}
                      validationRules={validationRules}
                      imgProps={getImgProps(object)}
                      onReplace={(file) => replaceMedia(object, file)}
                    />

                    {!!objectErrors.length ? (
                      <ItemErrors objectErrors={objectErrors} onBack={() => clearObjectErrors(object)} />
                    ) : (
                      <>
                        {propertiesView({ object })}

                        {fieldsView({
                          object,
                          getCustomPropertyInputProps: (propertyName) => getCustomPropertyInputProps(object, propertyName),
                          getCustomPropertyInputErrors: (propertyName) => getCustomPropertyInputErrors(object, propertyName),
                          getNameInputProps: () => getNameInputProps(object),
                          getNameInputErrors: () => getNameInputErrors(object),
                        })}
                      </>
                    )}

                    <div className="flex cursor-pointer p-2" onClick={() => removeMedia(object)} {...{ dusk: 'remove' }}>
                      <Icon icon="remove" className="shrink-0" />
                    </div>
                  </div>
                );
              })}
            </ReactSortable>
          </div>
        )}

        <HiddenFields name={name} mediaState={state.media} />

        <div className={!maxItems || state.media.length < maxItems ? 'media-library-uploader' : 'media-library-hidden'}>
          <Uploader {...dropZoneProps} {...fileInputProps} add capture={capture} multiple fileTypeHelpText={fileTypeHelpText} />
        </div>
      </div>
    </>
  );
};

export default MediaLibraryCollection;
