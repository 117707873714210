import isPropValid from '@emotion/is-prop-valid';
import { createInertiaApp, Link } from '@inertiajs/react';
import { mdiExclamation } from '@mdi/js';
import Icon from '@mdi/react';
import { PrimaryButton } from '@webfox/webfox-ui';
import AdminMainLayout from 'Layouts/Admin/MainLayout';
import MainLayout from 'Layouts/MainLayout';
import * as React from 'react';
import { createElement, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { StyleSheetManager } from 'styled-components';
import { FormErrorContextProvider } from 'Support/Contexts/FormErrorContext';
import { InitialApplicationContext } from 'Support/Contexts/InitialApplicationContext';

const ErrorFallback = () => (
  <div className="max-w-screen-3xl mt-60 flex h-full w-full flex-col items-center justify-center text-gray-800">
    <div>
      <Icon path={mdiExclamation} className="h-20 w-20 rounded-full bg-red-200 text-red-700" />
    </div>
    <div className="mt-3 text-2xl font-medium sm:mt-5">Whoops! Something went wrong.</div>
    <div className="mt-2">Developers have been notified.</div>
    <div className="mt-5 flex gap-4 sm:mt-6">
      <PrimaryButton className="bg-red-700" onClick={() => window.location.reload()}>
        Reload
      </PrimaryButton>
      <Link href={route('logout')}>
        <PrimaryButton className="bg-red-700">Logout</PrimaryButton>
      </Link>
    </div>
  </div>
);

export const initErrorTracking = async () => {
  if (window.sentryDsn) {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: window.sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      environment: window.sentryEnvironment,
      tracesSampleRate: window.sentryTracesSampleRate,
    });
  }
};

export const initInertiaApp = ({ resolve: resolveComponent, progress }) => {
  initErrorTracking();

  createInertiaApp({
    progress: progress === undefined ? { showSpinner: true } : progress,
    page: window.inertiaInitialPageData,
    resolve: (name) =>
      resolveComponent(name)
        .then((component) => {
          const defaultLayout = (page) => (name.startsWith('Admin/') ? <AdminMainLayout children={page} /> : <MainLayout children={page} />);
          component.layout = component.layout || defaultLayout;
          return component;
        })
        .catch((e) => {
          console.error(e);
          throw new Error(`The above error occurred when trying to load the page component for "${name}" and prevented it loading.`, {
            cause: e,
          });
        }),
    setup: ({ el, App, props }) => {
      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={props.initialPage.props.application}>
            <StyleSheetManager shouldForwardProp={isPropValid}>
              <App {...props}>
                {({ key, props, Component }) => {
                  const page = createElement(Component, { key, ...props });
                  const children = Component.layout(page);

                  return (
                    <FormErrorContextProvider>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
                      <ToastContainer autoClose={3000} pauseOnFocusLoss pauseOnHover position="bottom-center" />
                    </FormErrorContextProvider>
                  );
                }}
              </App>
            </StyleSheetManager>
          </InitialApplicationContext.Provider>
        </StrictMode>
      );
    },
  });
};
