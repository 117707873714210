import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import CheckboxField from 'Components/Forms/CheckboxField';
import Formik from 'Components/Forms/Formik';
import SelectField from 'Components/Forms/SelectField';
import SpatieUploadField from 'Components/Forms/SpatieUploadField';
import TextField from 'Components/Forms/TextField';
import FieldWrapper from 'Components/Forms/VerticalFieldWrapper';
import Panel from 'Components/Panel';
import { Form } from 'formik';
import React, { useEffect, useState } from 'react';
import axios from 'Support/axios';
import useInertiaForm from 'Support/Hooks/useInertiaForm';
import * as Yup from 'yup';

const AddRecordPanel = ({ assemblyId, onClose, open, projectId }) => {
  const [availableAssemblies, setAvailableAssemblies] = useState([]);
  const [availableLots, setAvailableLots] = useState([]);
  const [consumables, setConsumables] = useState([]);
  const [currentAssembly, setCurrentAssembly] = useState(null);
  const [panelData, setPanelData] = useState(null);

  useEffect(() => {
    if (open) {
      axios.get(route('data.assembly-record-fields')).then(({ data }) => {
        let consumables = Object.values(data.consumables);

        consumables = consumables
          .map((consumable) => ({
            label: consumable.name,
            options: consumable?.batches?.map((cons) => ({
              label: consumable.name + ' - ' + cons.number,
              value: cons.id,
            })),
          }))
          .flatMap((group) => group.options);

        setConsumables(consumables);
        setPanelData(data);
      });
    }
  }, [open]);

  const formikProps = useInertiaForm({
    defaultValues: {
      assembly_id: '',
      consumables: [],
      date_complete: '',
      fabricating_company_id: '',
      fabrication_inspectors: [],
      fabricators: [],
      image: [],
      lots: '',
      part_inspectors: [],
      project_id: '',
      quantity_fabricated: 0,
      revision: '',
      setDefaultFabricatingCompany: false,
      time: '',
      tolerance_inspectors: [],
      visual_examiners: [],
      visual_scanners: [],
      welders: [],
      what: 'assembly',
    },
    validationSchema: Yup.object().shape({
      project_id: Yup.string().required('Please select a project'),
      assembly_id: Yup.string().required('Please select an assembly'),
      quantity_fabricated: Yup.number()
        .required('The quantity has to be larger then 0')
        .max((currentAssembly?.quantity || 0) - (currentAssembly?.quantity_complete || 0))
        .moreThan(0),
      date_complete: Yup.date().required('Please select a date'),
    }),
    submit: ({ post, reset }) =>
      post(route('assembly-records.store', { save: true }), {
        preserveState: false,
        onSuccess: () => {
          onClose();
          reset();
        },
      }),
  });

  return (
    <Panel open={open} onClose={onClose}>
      <div className="w-screen max-w-md">
        {panelData ? (
          <Formik {...formikProps}>
            {({ setFieldValue, submitDisabled, values: { assembly_id, fabricating_company_id, fabricators, project_id, what, ...other } }) => {
              const getCurrentProject = () => panelData.projects.find((project) => project.id === parseInt(project_id));

              useEffect(() => {
                if (projectId) {
                  setFieldValue('project_id', parseInt(projectId));
                }
              }, []);

              useEffect(() => {
                setAvailableAssemblies(panelData.assemblies.filter((assembly) => assembly.project_id === parseInt(project_id)));
                setAvailableLots(getCurrentProject()?.lots || []);
                setFieldValue('assembly_id', assemblyId || '');
              }, [project_id]);

              useEffect(() => {
                setCurrentAssembly(panelData.assemblies.find((assembly) => assembly.id === parseInt(assembly_id)));

                setFieldValue('revision', currentAssembly?.revision || '');
                setFieldValue('lots', currentAssembly?.lots || '');
              }, [assembly_id]);

              useEffect(() => {
                if (fabricators) {
                  const weldingSupervisors = fabricators.filter((fabricator) => {
                    const weldingSupervisorIds = panelData.weldingSupervisors.map((weldingSupervisor) => parseInt(weldingSupervisor.id));
                    return weldingSupervisorIds.includes(parseInt(fabricator));
                  });

                  setFieldValue('part_inspectors', fabricators);
                  setFieldValue('fabrication_inspectors', fabricators);
                  setFieldValue('welders', fabricators);
                  setFieldValue('visual_scanners', weldingSupervisors);
                  setFieldValue('visual_examiners', weldingSupervisors);
                  setFieldValue('tolerance_inspectors', fabricators);
                }
              }, [fabricators]);

              useEffect(() => {
                if (!fabricating_company_id) {
                  setFieldValue('fabricating_company_id', parseInt(panelData?.defaultFabricatingCompany));
                }
              }, [panelData?.defaultFabricatingCompany]);

              return (
                <Panel.Body as={Form}>
                  <Panel.Header title="Add Assembly Record" />

                  <FieldWrapper name="project_id" label="Project">
                    <SelectField options={panelData.projects} labelKey="full_name" valueKey="id" />
                  </FieldWrapper>

                  {availableAssemblies.length > 0 ? (
                    <FieldWrapper name="assembly_id" label="Assembly">
                      <SelectField options={availableAssemblies} labelKey="name" valueKey="id" />
                    </FieldWrapper>
                  ) : (
                    <div>No assemblies for the chosen project</div>
                  )}

                  {what === 'assembly' && !!assembly_id && (
                    <>
                      <FieldWrapper name="lots" label="Lots">
                        <SelectField options={availableLots} valueKey="id" labelKey="full_name" className="grow" />
                      </FieldWrapper>

                      <FieldWrapper name="revision" label="Revision">
                        <TextField />
                      </FieldWrapper>

                      <FieldWrapper name="quantity_fabricated" label="Quantity Fabricated">
                        <TextField type="number" max={(currentAssembly?.quantity || 0) - (currentAssembly?.quantity_complete || 0)} />
                      </FieldWrapper>

                      <FieldWrapper name="fabricating_company_id" label="Fabrication Company">
                        <SelectField options={panelData.companies} valueKey="id" labelKey="name" className="grow" />
                      </FieldWrapper>

                      {fabricating_company_id && panelData?.defaultFabricatingCompany != fabricating_company_id ? (
                        <div className="rounded bg-red-500 p-5 text-white">
                          <FieldWrapper name="setDefaultFabricatingCompany" className="flex items-center text-sm">
                            <CheckboxField label="Do you want to set this company as the default fabricating company?" />
                          </FieldWrapper>
                        </div>
                      ) : (
                        <></>
                      )}

                      <FieldWrapper name="fabricators" label="Fabricators">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.fabricators} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="consumables" label="Consumables Used">
                        <SelectField className="grow" isMulti options={consumables} />
                      </FieldWrapper>

                      <FieldWrapper name="part_inspectors" label="Part Inspectors">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.fabricators} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="fabrication_inspectors" label="Fabrication Inspectors">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.fabricators} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="welders" label="Welders">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.fabricators} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="visual_scanners" label="Visual Scanners">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.weldingSupervisors} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="visual_examiners" label="Visual Examiners">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.weldingSupervisors} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="tolerance_inspectors" label="Tolerance Inspectors">
                        <SelectField className="grow" isMulti labelKey="full_name" options={panelData.fabricators} valueKey="id" />
                      </FieldWrapper>

                      <FieldWrapper name="image" label="Photo" as="div">
                        <SpatieUploadField multiFile imageOnly capture="camera" />
                      </FieldWrapper>

                      <FieldWrapper name="date_complete" label="Date">
                        <TextField type="date" />
                      </FieldWrapper>

                      <FieldWrapper name="time" label="Time">
                        <TextField type="number" />
                      </FieldWrapper>
                    </>
                  )}

                  <Panel.Actions>
                    <SecondaryButton type="button" onClick={onClose}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={submitDisabled}>
                      Add
                    </PrimaryButton>
                  </Panel.Actions>
                </Panel.Body>
              );
            }}
          </Formik>
        ) : (
          <Panel.Body as="div">
            <Panel.Header title="Add Assembly Record" />
            <div className="flex justify-center pt-20">
              <Icon path={mdiLoading} className="h-36 animate-spin" />
            </div>
          </Panel.Body>
        )}
      </div>
    </Panel>
  );
};

export default AddRecordPanel;
