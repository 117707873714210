import { Head, Link, usePage } from '@inertiajs/react';
import { mdiDomain, mdiMenu, mdiViewDashboardOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import Logo from 'Layouts/Partials/Logo';
import MobileSideBar from 'Layouts/Partials/MobileSideBar';
import SideBar from 'Layouts/Partials/SideBar';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ScrollContext from 'Support/Contexts/ScollContext';
import useInitialApplication from 'Support/Hooks/useInitialApplication';
import useUser from 'Support/Hooks/useUser';

const MainLayout = ({ children }) => {
  const user = useUser();
  const { site_name, icon } = useInitialApplication();
  const { flash, page_title } = usePage().props;
  const [collapsed, setCollapsed] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollWrapper = useRef();

  const menu = useMemo(
    () => [
      {
        icon: mdiViewDashboardOutline,
        name: 'Dashboard',
        url: route('admin.dashboard'),
      },
      {
        icon: mdiDomain,
        name: 'Organisations',
        url: route('admin.organizations.index'),
      },
    ],
    [],
  );

  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, message }) => {
        toast(message, { type });
      });
    }
  }, [JSON.stringify(flash)]);

  const [mobileSideNavOpen, setMobileSideNavOpen] = useState(false);

  const onScroll = () => {
    setScrollTop(scrollWrapper.current.getBoundingClientRect().top);
  };

  return (
    <>
      <Head title={page_title ? `${page_title} | ${site_name}` : site_name}>
        <link rel="icon" type="image/png" href={icon} />
      </Head>

      <div className={cls('flex h-screen flex-col font-main', user.hasPermission('view-desktop') && 'md:hidden')}>
        <div className="flex h-16 flex-row items-center justify-between px-5">
          <button onClick={() => setMobileSideNavOpen(true)} className="text-white">
            <Icon path={mdiMenu} size={1.5} />
          </button>
          <Link href={route('dashboard')} className="flex justify-center grow">
            <Logo className="h-16" inAdmin />
          </Link>
        </div>
        {children}
      </div>

      <MobileSideBar menu={menu} quickAddRecord={false} open={mobileSideNavOpen} onClose={() => setMobileSideNavOpen(false)} />

      <div
        className={cls(
          'hidden h-screen grid-flow-col overflow-hidden font-main',
          collapsed ? 'grid-cols-collapsed' : 'grid-cols-main',
          user.hasPermission('view-desktop') && 'md:grid',
        )}
      >
        <SideBar menu={menu} quickAddRecord={false} collapsed={collapsed} inAdmin setCollapsed={setCollapsed} />

        <div className="flex flex-col overflow-y-auto overflow-x-hidden bg-gray-50">
          <div className="relative flex h-screen w-full flex-col overflow-x-hidden overflow-y-visible" scroll-region="true" onScroll={onScroll}>
            <div ref={scrollWrapper}>
              <ScrollContext.Provider value={{ sidebarCollapsed: collapsed, top: scrollTop }}>{children}</ScrollContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
