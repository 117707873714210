import Icon from '@mdi/react';
import cls from 'classnames';
import React, { forwardRef } from 'react';

const SecondaryButton = ({ children, className = '', startIcon, endIcon, as = 'button', ...props }, ref) => {
  const Element = as;

  if (!className.includes('h-')) {
    className += cls(className, 'h-11');
  }

  return (
    <Element
      ref={ref}
      className={cls(
        'inline-flex items-center justify-center gap-2 border bg-white text-gray-700 transition duration-150 ease-in-out',
        'rounded-md text-base shadow shadow-gray-400/10',
        'hover:cursor-pointer hover:text-red-700 hover:opacity-90 focus:outline-none',
        'disabled:cursor-default disabled:opacity-50',
        !!startIcon ? 'pl-3' : 'pl-4',
        !!endIcon || !children ? 'pr-3' : 'pr-4',
        className
      )}
      {...props}
    >
      {startIcon ? <Icon path={startIcon} size={1} /> : null}
      {children}
      {endIcon ? <Icon path={endIcon} size={1} /> : null}
    </Element>
  );
};

export default forwardRef(SecondaryButton);
